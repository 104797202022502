import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
export default function menu(drawer) {

  //  ドロワー
  var state = false;
  $("#drawerBtn").on("click", function () {
    if (state == false) {
      document.documentElement.style.overflow = 'hidden';
      disableBodyScroll(document.querySelector('.drawer-modal'));
      $(this).addClass('active');
      $('.drawer-modal').addClass('open');
      state = true;
    } else {
      document.documentElement.style.overflow = '';
      enableBodyScroll(document.querySelector('.drawer-modal'));
      $(this).removeClass('active');
      $('.drawer-modal').removeClass('open');
      state = false;
    }
  });

  $('.js-close').on('click', function () {
    $('#drawerBtn').click();
    $('.drawer-modal').removeClass('open');
    state = false;
  });
}
