export default function menu() {

  // 途中からボタンを表示
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $('#pagetop').fadeIn();
    } else {
      $('#pagetop').fadeOut();
    }
  });
}
